import log from './log';

const baseUrl = '/api';
const _ = require('../services/comparators');

const getAuthHeaders = () => {
    const authToken = localStorage.getItem('token');
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
    }
}

const getHeaders = () => {
  return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  }
}

const getAPIHeaders = (apiKey) => {
  return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': apiKey
  }
}

const toJson = (result) => {
    return result.then(data => data.json()).then((data) => data)
}

const Fetcher = {
    signInUser: (name, email) => {
        return toJson(fetch(`${baseUrl}/users/signin`, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
              name: name,
              email: email
            })}))
    },
    refreshToken: (name, email) => {
        return toJson(fetch(`${baseUrl}/users/signin`, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
              name: name,
              email: email
            })}))
    },
    getBlogPost: (url) =>{
        return toJson(fetch(`${baseUrl}${url}`))
    },
    getBlogPosts: () => {
        return toJson(fetch(`${baseUrl}/blog`))
    },
    getMetals: () => {
      return toJson(fetch(`${baseUrl}/metals`))
    },
    updateBillingDetails: (cardToken, price, promo) => {
        return toJson(fetch(`${baseUrl}/users/customer`, {
          method: 'post',
          headers: getAuthHeaders(),
          body: JSON.stringify({
            sourceToken: cardToken.id,
            price: price,
            promo: promo
          })}))
    },
    unsubscribeCustomer: () => {
      return toJson(fetch(`${baseUrl}/users/unsubscribe`, {
        method: 'post',
        headers: getAuthHeaders()}))
    },
    redirectToCustomerPortal: () => {
      log.traceTag('redirectToCustomerPortal')
      return toJson(fetch(`${baseUrl}/users/customer-portal`,{
        method: 'post',
        headers: getAuthHeaders()
      }))
    },
    callStatusAPI: (apiKey) => {
      return toJson(fetch(`${baseUrl}/status`, {
        method: 'get',
        headers: getAPIHeaders(apiKey)}))
    },
    callPriceAPI: (apiKey, symbol, curr, date) => {
      return toJson(fetch(`${baseUrl}/${symbol}/${curr}/${date}`, {
        method: 'get',
        headers: getAPIHeaders(apiKey)}))
    },
    callRequestsStatAPI: (apiKey) => {
      return toJson(fetch(`${baseUrl}/stat`, {
      method: 'get',
      headers: getAPIHeaders(apiKey)}))
    },
      // STRIPE

    getPlans: (country) => {
      return toJson(fetch(`${baseUrl}/plans/${country}`, {
        headers: getHeaders()
      }))
    },

    createCheckoutSession: (plan) => {
      return toJson(fetch(`${baseUrl}/users/create-checkout-session`,{
        method: 'post',
        headers: getAuthHeaders(),
        body: JSON.stringify(plan)
      }))
    }
}

export default Fetcher;