import React from 'react';
import Slider from 'react-slick';
import withSizes from 'react-sizes';

import CodeBlock from './CodeBlock'

import '../css/Uvp.css';

function Uvp(props){

  const request = `curl -X GET 'https://www.goldapi.io/api/XAU/USD/20230617' \n     -H 'x-access-token: YOUR_API_KEY`;

  const response = JSON.stringify({
    "timestamp":1591149337,
    "metal":"XAU",
    "currency":"USD",
    "ask":1728.4,
    "bid":1727.08,
    "price":1727.75,
    "price_gram_22K":63.75,
    "ch":0.01,
    "chp":-0.02
  }, null, 2);

  return (
    <div className="uvp-parent">
      <>
          {props.isDesktop && 
            <div className="uvp-preview mt-3 mb-4 row justify-content-center align-items-center">
              <div style={{width: 700+'px'}}>
                {/*<div id="bar">
                  <div id="button-red"></div>
                  <div id="button-yellow"></div>
                  <div id="button-green"></div>
                </div>
                <div id="screen">
                  <p className="command">curl -X GET 'https://www.goldapi.io/api/XAU/USD/20230617' \<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;-H 'x-access-token: Your_API_Key'</p>
                  <p className="response"><div className="preformatted">{JSON.stringify({
                    "timestamp":1591149337,
                    "metal":"XAU",
                    "currency":"USD",
                    "ask":1728.4,
                    "bid":1727.08,
                    "price":1727.75,
                    "ch":0.01,
                    "chp":-0.02
                  }, null, 2) }</div></p>
                </div>*/}
                <div>
                  <CodeBlock language="js" value={`// Request:
${request}`}/>
                  <CodeBlock language="js" value={`// API Response: 
${response}`}/>
                </div>
              </div>
            </div>
          }
          {props.isMobile && 
            <div>
              <CodeBlock language="js" value={request}/>
             <CodeBlock language="js" value={response}/>
            </div>
          }
        </>
      </div>
  );
};

Uvp.defaultProps = {
  landing: true 
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 700,
  isDesktop: width >= 700
})


export default withSizes(mapSizesToProps)(Uvp);