import React from 'react';
import { withRouter } from 'react-router';

function CWIcon(props){
  return (
    <>
      {props.theme === 'light' &&
        <img style={{width: props.size+'px', height: props.size+'px'}} src={`data:image/svg+xml,%3Csvg width='100px' height='100px' viewBox='0 0 559 441' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 64%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vectorstock_36801408' transform='translate(-1837.000000, -1277.000000)' fill='%23FFFFFE'%3E%3Cpath d='M2395.7,1455.72 L2377.37,1425.99 L2221.29,1522.2 L2228.16,1408.35 L2043.46,1522.2 L2050.33,1408.35 L1931.45,1481.63 C1939.67,1386.81 2019.44,1312.14 2116.35,1312.14 C2197.61,1312.14 2266.8,1364.64 2291.89,1437.49 L2321.98,1418.94 C2290.26,1336.27 2210.19,1277.56 2116.35,1277.56 C1994.73,1277.56 1896.13,1376.16 1896.13,1497.78 C1896.13,1499.64 1896.23,1501.47 1896.27,1503.31 L1837.01,1539.84 L1855.34,1569.57 L2011.42,1473.37 L2004.54,1587.21 L2189.25,1473.37 L2182.37,1587.21 L2301.25,1513.94 C2293.03,1608.76 2213.27,1683.43 2116.35,1683.43 C2034.92,1683.43 1965.62,1630.7 1940.66,1557.62 L1910.55,1576.17 C1942.15,1659.08 2022.35,1718 2116.35,1718 C2237.98,1718 2336.57,1619.41 2336.57,1497.78 C2336.57,1495.93 2336.48,1494.1 2336.43,1492.25 L2395.7,1455.72' id='Fill-64'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E`}/>
      }
      {props.theme === 'dark' &&
        <img style={{width: props.size+'px', height: props.size+'px', paddingBottom: 3+'px'}} src={`data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='100px' height='100px' viewBox='0 0 559 441' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 64%3C/title%3E%3Cdefs%3E%3ClinearGradient x1='37.6533513%25' y1='38.3318481%25' x2='88.0763646%25' y2='50%25' id='linearGradient-1'%3E%3Cstop stop-color='%23916c00' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23a67c00' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vectorstock_36801408' transform='translate(-1837.000000, -1277.000000)' fill='url(%23linearGradient-1)'%3E%3Cpath d='M2395.7,1455.72 L2377.37,1425.99 L2221.29,1522.2 L2228.16,1408.35 L2043.46,1522.2 L2050.33,1408.35 L1931.45,1481.63 C1939.67,1386.81 2019.44,1312.14 2116.35,1312.14 C2197.61,1312.14 2266.8,1364.64 2291.89,1437.49 L2321.98,1418.94 C2290.26,1336.27 2210.19,1277.56 2116.35,1277.56 C1994.73,1277.56 1896.13,1376.16 1896.13,1497.78 C1896.13,1499.64 1896.23,1501.47 1896.27,1503.31 L1837.01,1539.84 L1855.34,1569.57 L2011.42,1473.37 L2004.54,1587.21 L2189.25,1473.37 L2182.37,1587.21 L2301.25,1513.94 C2293.03,1608.76 2213.27,1683.43 2116.35,1683.43 C2034.92,1683.43 1965.62,1630.7 1940.66,1557.62 L1910.55,1576.17 C1942.15,1659.08 2022.35,1718 2116.35,1718 C2237.98,1718 2336.57,1619.41 2336.57,1497.78 C2336.57,1495.93 2336.48,1494.1 2336.43,1492.25 L2395.7,1455.72' id='Fill-64'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E`}/>
      }
    </>
  );
};

CWIcon.defaultProps = {
  size: 24,
  theme: 'light', // dark
  disabled: false
}

export default CWIcon;