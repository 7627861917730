import React from 'react';
import { Helmet } from "react-helmet";
import { Redirect, Link} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Section from './components/Section';
import Uvp from './components/Uvp';
import Footer from './components/Footer';
import SignInButton from './components/buttons/SignInButton';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Customers from './components/Customers';

import auth from './services/auth';

import '@rmwc/tooltip/styles';
import '@rmwc/button/styles';

function Landing(){
  console.log(process.env.NODE_ENV)

  if (auth.isLogged()) {
    return <Redirect to={{
      pathname: '/dashboard'
    }}/>;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Free Real-Time Gold and Silver Spot Prices REST JSON API | GoldAPI.io</title>
        <meta name="description" content="Free Real-Time Gold, Silver Platinum & Palladium Spot Prices RESTful JSON API. Live Feed of Gold & Silver Spot Prices from FOREX, SAXO, OANDA and IDC Exchanges."></meta>
      </Helmet>
      <Section 
        showScrollTo={true}
        showNav={false}
        header='GoldAPI.io is'
        landing
        subtitle='100% Real-Time & Historical Gold, Silver, Platinum & Palladium Spot Prices from FOREX and LBMA for Your Business & Customers'>
        <Uvp></Uvp>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
      </Section>
      <Section 
        showScrollTo={true}
        showNav={false}
        header='Get Instant Access To'
        title='Live Feed of Precious Metals Spot Prices' 
        subtitle='From One Simple & Free API'>
        <Features></Features>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
      </Section>
      <Section 
        showScrollTo={true}
        showNav={false}
        header='We Proudly Serve'
        title='Our Customers Around The World' 
        subtitle='Accurate and Reliable Metal Prices for Gold Trading, Manufacturing, Recycling and Bullion Businesses'>
        <Customers></Customers>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
      </Section>
      <Section 
        showScrollTo={false}true
        showNav={false}
        header='Simple, Worry-free, Flat Pricing With No Surprises'
        title="Start Today and Test for Free"
        subtitle='No Card Required. Upgrade To Unlimited Plan in One Click via Stripe. 30 Days Money Back Guarantee.'>
        <Pricing></Pricing>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
        <Footer></Footer>
      </Section>
    </div>
    </GoogleOAuthProvider>
  );
}

export default Landing;
