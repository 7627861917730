import React from 'react';

function Features() {
  return (
    <div>
      <div className="row mt-3 mb-5 mx-5 text-center">
      <div className="col-md-3 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/64/000000/refresh.png"
              width="50px"
              alt="Live Gold & Silver Prices API"/>
          </div>
          <h4>100% <span className="hl-gold">Real-Time</span></h4>
          <span className="text-muted">Live Feed of <b>XAU</b>, <b>XAG</b>, <b>XPT</b>, <b>XPD</b> from FOREX, SAXO, OANDA and IDC Exchanges</span>
        </div>
        <div className="col-md-3 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/64/000000/currency-exchange.png"
              width="50px"
              alt="Gold & Silver Prices API"/>
          </div>
          <h4>All <span className="hl-silver">Currencies</span></h4>
          <span className="text-muted">Live Metal Spot Prices in <b>USD</b>, <b>EUR</b>, <b>INR</b>, <b>AED</b>, <b>KWD</b>, <b>EGP</b>, <b>SAR</b> and 25 other currencies</span>
        </div>
        <div className="col-md-3 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios-glyphs/64/000000/diamond-2.png"
              width="50px"
              alt="Gold & Silver Prices API"/>
          </div>
          <h4><span className="hl-gold">Grams & Karats</span></h4>
          <span className="text-muted">Get prices in Troy Ounces, Grams and <b>Karats</b> (24, 22, 21, 20, 18, 16, 14, 10) Purity Grade</span>
        </div>
        <div className="col-md-3 my-2">
            <div className="m-3">
              <img
                src="https://img.icons8.com/ios/64/000000/activity-history.png"
                width="50px"
                alt="Historical Gold & Silver Prices API"/>
            </div>
            <h4>Prices Since <span className="hl-silver">1968</span></h4>
            <span className="text-muted">Historical LBMA AM Fix Gold & Silver Prices Since 1968</span>
          </div>
      </div>
    </div>
  );
};

export default Features;