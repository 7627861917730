import React from 'react';

function Customers() {
  return (
    <div>
      <div className="row mt-3 mb-5 mx-5 text-center">
      <div className="col-md-3 my-auto">
          <div className="m-3">
            <img className='my-4'
              src="https://steel-pipe.de/wp-content/uploads/2023/08/logo.jpg"
              width="200px"
              alt="Live Gold & Silver Prices API"/>
          </div>
          <h4><span className="hl-gold">Steel Pipe GmbH</span></h4>
          <div className='my-2 small'><span className="text-muted dashed">Germany</span></div>
          <div><span className="text-muted">Steel Tubes and Pipes Production</span></div>
        </div>
        <div className="col-md-3 my-auto">
          <div className="m-3">
            <img className="rounded-circle"
              src="https://pbs.twimg.com/profile_images/1391761721463697408/zTLtfP19_400x400.jpg"
              width="85px"
              alt="Gold & Silver Prices API"/>
          </div>
          <h4><span className="hl-silver">Algomint</span></h4>
          <div className='my-2 small'><span className="text-muted dashed">Global</span></div>
          <div><span className="text-muted">Algorand Blockchain Interoperability</span></div>
        </div>
        <div className="col-md-3 my-auto">
          <div className="m-3">
            <img
              src="https://cdn.shopify.com/s/files/1/0642/8209/9953/files/siddi_logo_p1_v16_1920x1080_trajan_ACAPS.png?v=1654287106&width=100"
              width="100px"
              alt="Gold & Silver Prices API"/>
          </div>
          <h4><span className="hl-gold">My Gold Grans Inc</span></h4>
          <div className='my-2 small'><span className="text-muted dashed">USA</span></div>
          <div><span className="text-muted">Gold Bullion Products</span></div>
        </div>
        <div className="col-md-3 my-auto">
            <div className="m-3">
              <img 
                src="https://royalrafflescapital.com/assets/images/icons/logo-royal.png"
                width="80px"
                alt="Historical Gold & Silver Prices API"/>
            </div>
            <h4><span className="hl-silver">Royal Raffles Capital</span></h4>
            <div className='my-2 small'><span className="text-muted dashed">Indonesia</span></div>
            <span className="text-muted">Independent Private Gold Trading</span>
          </div>
      </div>
    </div>
  );
};

export default Customers;